import client from "@/store/client.js";
import store from "@/store/store";

export const namespaced = true;

export const state = {	
    contracts: [],
    dismantlercontracts: [],
    remancontracts: [],
};

export const mutations = {
    clearContracts(state) {
        state.contracts = [];
    },  
    setContracts(state, data) {          
        data.forEach(element => {            
            state.contracts.push(element)
        });               
    },
    clearRemanContracts(state) {
        state.remancontracts = [];
    },  
    setRemanContracts(state, data) {          
        data.forEach(element => {            
            state.remancontracts.push(element)
        });               
    },
    clearDismantlerContracts(state) {
        state.dismantlercontracts = [];
    },  
    setDismantlerContracts(state, data) {          
        data.forEach(element => {            
            state.dismantlercontracts.push(element)
        });               
    }
}

export const actions = {
    getContractsByDid({commit},dismantlerId) {
        return client.core.get('/reman/contract/by/dismantler/' + dismantlerId)
        .then((r) => {
            commit("clearDismantlerContracts")
            commit("setDismantlerContracts", r.data)           
            return r.data
        })
    },
    getContractsByRid({commit},remanId) {
        return client.core.get('/reman/contract/by/reman/' + remanId)
        .then((r) => {
            commit("clearRemanContracts")
            commit("setRemanContracts", r.data)           
            return r.data
        })
    },
    createDismantlerContracts({commit}, dismantler) {
        return client.core.put('/admin/reman/contract/create/all/dismantler/' + dismantler.id)
        .then((r) => {
            commit("clearDismantlerContracts")
            commit("setDismantlerContracts", r.data)  
            store.dispatch(
                "messageHandling/addMessage",
                'Verträge für ' + dismantler.company + ' erfolgreich angelegt'
            );           
            return r.data
        })
    },
    createRemanContracts({commit},remanId) {
        return client.core.put('/admin/reman/contract/create/all/reman/' + remanId)
        .then((r) => {
            commit("clearRemanContracts")
            commit("setRemanContracts", r.data)
            store.dispatch(
                "messageHandling/addMessage",
                'Verträge für Wiederaufbereiter ID: ' + remanId + ' erfolgreich angelegt'
            );          
            return r.data
        })
    }
}