import client from "@/store/client.js";
import Rights from "@/Rights.js";
import store from "@/store/store";

export const namespaced = true;

export const state = {	
    dismantlers: [],
};

export const mutations = {
    cleardismantlers(state) {
        state.dismantlers = [];
    },  
    setdismantlers(state, data) {          
        data.forEach(element => {            
            state.dismantlers.push(element)
        });               
    }
}

export const actions = {
    getDismantlers({commit}) {
        return client.core.get('/dismantlers')
        .then((r) => {
            commit("cleardismantlers")
            commit("setdismantlers", r.data)            
            return r.data
        })
    }
}