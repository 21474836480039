import client from "@/store/client.js";
import store from "@/store/store";
//import LastReception from '@/store/classes/LastReception.js'
export const namespaced = true;

export const state = {
    lastreceptions: []
}

export const mutations = {
    clearLastReceptions(state) {
        state.lastreceptions = []
    },
    setLastReceptions(state, data) {
        data.forEach(element => {
            //state.lastreceptions.push(new LastReception(element))
            state.lastreceptions.push(element)
        }); 
    }
}

export const actions = {
    getLastReceptions({commit}, data) {
        return client.core.get('/admin/vnticker').then(lastreceptions => {
            if(lastreceptions.status === 200) {
                commit("clearLastReceptions")
                commit("setLastReceptions", lastreceptions.data)
            }
        })
    }
}