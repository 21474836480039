import client from "@/store/client.js";
import Rights from "@/Rights.js";
import store from "@/store/store";

export const namespaced = true;

export const state = {	
    mandators: [],
    mandator: null
};

export const mutations = {
    clearMandators(state) {
        state.mandators = [];
    },  
    setMandators(state, data) {          
        data.forEach(element => {
            state.mandators.push(element)
        });               
    },
    updatemandator(state, newdata) {
        let mandator = state.mandators.find(
            r => r.id === newdata.id      
        );
        Object.assign( mandator, newdata);
    },
    clearmandator(state) {
        state.mandator = null;
    },  
    setmandator(state, data) {          
        state.mandator = data;
    },
}

export const actions = {
    getMandators({commit}) {
        return client.core.get('/admin/mandators')
        .then((r) => {
            r.data.forEach(element => {
                element.rights = new Rights(element.permissions)
            });
            commit("clearMandators")
            commit("setMandators", r.data)            
            return r.data
        })
    },
    updateWeightDifference({commit}, mandatorId) {
        return client.core.put('/admin/btb/car/weightdifference/' + mandatorId)
        
    },
    getMandatorDetails({commit}, id) {
        return client.core.get('/admin/mandators/' + id)
        .then((r) => {
            r.data.rights = new Rights(r.data.permissions)
            commit('clearmandator')
            commit('setmandator', r.data)                    
            return r.data   
        })
    },
    updateMandator({commit}, data) {
        return client.core.patch('/admin/mandators/' + data.id, data)
        .then((r) => {
            store.dispatch(
                "messageHandling/addMessage",
                'Mandant erfolgreich bearbeitet'
            );
            commit('updatemandator', r.data)
            return r.data
        })
    }
}