import client from "@/store/client.js";
import store from '@/store/store'

export const namespaced = true;

export const state = {	
    sharedDisposers: [],
    disposer: null,
    nonsharedDisposers: []
};

export const mutations = {
    clearDisposers(state) {
        state.sharedDisposers = [];
        state.nonsharedDisposers = [];
    },  
    setDisposers(state, data) {          
        data.forEach(item => {
            if(item.shared === true) {
                state.sharedDisposers.push(item)
            } else {
                state.nonsharedDisposers.push(item)
            }            
        }); 
        /* state.sharedDisposers =  data.filter(item => item = item.shared === true)
        state.nonsharedDisposers =  data.filter(item => item = item.shared === false) */
    },
    updateDisposers(state, data) {
        state.nonsharedDisposers.splice(data.index,1)
    },
    clearDisposer(state) {
        state.disposer = null;
    },  
    setDisposer(state, data) {          
        state.disposer = data;
    }
}

export const actions = {
    getDisposers({ commit }) {                    
        return client.core.get('/btb/disposers/tree')
        .then((r) => {                    
            commit('clearDisposers')
            commit('setDisposers', r.data)
            return r.data          
        })
    },
    activateDisposer({ commit }, data) {                    
        return client.core.patch('/admin/btb/disposers/base/' + data.id, 'true', {headers: {"Content-Type": "application/json"}} )
        .then((r) => {
            commit("updateDisposers", data)            
            store.dispatch(
                "messageHandling/addMessage",
                'Entsorger erfolgreich aktiviert'
            );
            return r.data          
        })
    },
    updateDisposer({commit}, disposer) {
        return client.core.put('/admin/btb/disposers/base', disposer)
        .then((r) => {            
            store.dispatch(
                "messageHandling/addMessage",
                'Entsorger erfolgreich bearbeitet'
            );                           
            commit('clearDisposer')
            commit('setDisposer', r.data)
            return r.data
        })
    },
    getDisposerDetails({commit}, id) {
        return client.core.get('/btb/disposers/' + id)
        .then((r) => {
            commit('clearDisposer')
            commit('setDisposer', r.data)
            return r.data   
        })
    }
}