<template>
<v-dialog max-width="290" persistent v-if="showMbox">
    <template v-slot:activator="{on}">
        <v-btn id="mbox_btn_launch" v-on="on">
            Test
        </v-btn>
    </template>
    <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-card-text>{{msg}}</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="btnCap1" @click="btnClicked('1')">{{btnCap1}}</v-btn>
            <v-btn v-if="btnCap2" @click="btnClicked('2')">{{btnCap2}}</v-btn>
            <v-btn v-if="btnCap3" @click="btnClicked('3')">{{btnCap3}}</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    name: 'mbox',
    data: () => ({
        showMbox: true    
    }),
    props: [
        // these can be passed in, the 'data' stuff can't
        'msg',
        'title',
        'btnCap1',
        'btnCap2',
        'btnCap3',
        'retval' // watches this so we know when they clicked something
    ],
    created(){    
        this.showMbox = true;
    }, 
    methods: {
    btnClicked: function(mBtnClicked){
        // mBtnClicked = Char. Has to be a character in order for it to pass it in. Not sure why, numerics didn't work
        mBtnClicked = Number(mBtnClicked);
        this.retval = mBtnClicked; // watcher in Sublib will detect this value has changed
        this.showMbox = false;
    } // btnClicked
} // methods
} // export default
</script>
<style scoped>
</style>