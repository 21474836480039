const modules = {
    "STD" : {
        name: 'Stammdaten',
        index : 0,
        shorthand: "STD"
    },
    "RV" : {
        name : 'Benutzerverwaltung',
        index : 1,
        shorthand: "RV"
    },
    "VN" : {
        name : 'Fahrzeugannahme',
        index : 2,
        shorthand: "VN"
    },
    "BTB" : {
        name : 'Betriebstagebuch',
        index : 3,
        shorthand: "BTB"
    },
    "RMD" : {
        name : 'Ersatzteilangebote von Wiederaufbereiter',
        index : 4,
        shorthand: "RMD"
    },    
    "RM" : {
        name : 'Angebotserstellung durch Wiederaufbereiter',
        index : 5,
        shorthand: "RM"
    }
};

const accessTypes = {
    "C" : {
        index : 0,
        bit : "0001",
        value : 1,
        icon: "mdi-plus"
    },
    "R" : {
        index : 1,
        bit : "0010",
        value : 2,
        icon: "mdi-search"
        
    },
    "U" : {
        index : 2,
        bit: "0100",
        value : 4,
        icon: "mdi-pencil"
    },
    "D" : {
        index : 3,
        bit : "1000",
        value : 8,
        icon: "mdi-trash-can"
    }
}
let moduleNames = ["STD", "RV", "VN", "BTB", "RMD", "RM"];
let aTypes = ["C", "R", "U", "D"];

export default class Rights {
    constructor(rights, admin) {
      this.rights = rights;
      this.admin = admin;
      this.moduleRights = [];
      for(let name of moduleNames){
        let permissions = [];
        for(let type of aTypes){
            let permission = {name : type, value : this.accessGranted(name, type), icon: accessTypes[type].icon};
            permissions.push(permission);
        }        
        this.moduleRights.push({permissions: permissions, module : modules[name]});
      }
      
    }
    accessGranted(module, permissions){
        if(this.admin){
            return true;
        } else if (this.rights && module && permissions){
            const moduleIndex = modules[module.toUpperCase()]
            const slicedRightBlock = this.rights.substring(moduleIndex.index, moduleIndex.index+1);
            if(slicedRightBlock){
                for(let i=0, pL = permissions.length; i < pL; i++){
                    let accessType = accessTypes[permissions.charAt(i).toUpperCase()];
                    let result = parseInt(slicedRightBlock, 16) & parseInt(accessType.bit, 2)                    
                    if(result != accessType.value){
                        return false;
                    } 
                }
                return true;
            }
        }
        return false;
    }
    grantall() {
        let rights = "";        
        for(let moduleRight of this.moduleRights){
            let nibble = "";
            for(let permission of moduleRight.permissions){
                let value = 1;
                nibble = value + nibble;
            }
            nibble = parseInt(nibble,2).toString(16).toUpperCase();
            rights += nibble;
        }
        this.rights = rights;
        return this.rights
    }/* 
    checkall(permissions) {
        
        for(let type of aTypes) {
            let result = parseInt(accessTypes[type].bit, 2) 
            console.log(result)
        }
        let rights = "";
        for(let moduleRight of this.moduleRights){
            let nibble = "";
            for(let permission of moduleRight.permissions){
                let value = permission.value ? "1"  : "0";
                nibble = value + nibble;
            }
            nibble = parseInt(nibble,2).toString(16).toUpperCase();
            rights += nibble;
        }
        if(rights === permissions) {
            return true
        }
        return false
    } */
    updateRights(){
        let rights = "";
        for(let moduleRight of this.moduleRights){
            let nibble = "";
            for(let permission of moduleRight.permissions){
                let value = permission.value ? "1"  : "0";
                nibble = value + nibble;
            }
            nibble = parseInt(nibble,2).toString(16).toUpperCase();
            rights += nibble;
        }
        this.rights = rights;
        return this.rights
    }
  }
