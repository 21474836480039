import client from "@/store/client.js";
import store from '@/store/store'
import Certifier from '@/store/classes/Certifier.js'

export const namespaced = true;

export const state = {	    
    certifiers: [],
    certifier: new Certifier()
};

export const mutations = {
    clearcertifiers(state) {
        state.certifiers = []        
    },  
    setcertifiers(state, data) {          
        data.forEach(item => {
                state.certifiers.push(new Certifier(item))        
        }); 
    },
    clearcertifier(state) {
        state.certifier = new Certifier();
    },
    updatecertifiers(state, data) {
        state.certifiers.splice(data.index,1)     
    },  
    setcertifier(state, data) {          
        state.certifier = new Certifier(data);        
    },
    updatecertifier(state, newdata) {
        let certifier = state.certifiers.find(
            r => r.id === newdata.id      
        );
        Object.assign( certifier, newdata);
    },
}

export const actions = {
    getCertifiers({ commit }) {                    
        return client.core.get('/certifiers')
        .then((r) => {                    
            commit('clearcertifiers')
            commit('setcertifiers', r.data)
            return r.data          
        })
    },
    getCertifierDetails({commit}, id) {
        return client.core.get('/certifiers/' + id)
        .then((r) => {
            //commit('clearcertifier')
            commit('setcertifier', r.data)
            return r.data   
        })
    },
    saveCertifier({commit}, certifier) {
        return client.core.put('/admin/certifiers', certifier)
        .then((r) => {
            if(certifier.id === null) {
                store.dispatch(
                    "messageHandling/addMessage",
                    'Zertifizierer erfolgreich angelegt'
                );  
            } else {
                store.dispatch(
                    "messageHandling/addMessage",
                    'Zertifizierer erfolgreich bearbeitet'
                );         
            }            
            commit('clearcertifier')
            commit('setcertifier', r.data)
            return r.data
        })
    },
    deleteCertifier({commit}, data) {
        return client.core.delete('/admin/certifiers/' + data.id)
        .then((r) => {            
            if(r.data.status === 200) {         
                store.dispatch(
                    "messageHandling/addMessage",
                    'Zertifizierer erfolgreich gelöscht'
                );                                           
                commit('updatecertifiers', data)            
            } 
        })
    }
}