import client from "@/store/client.js";
import axios from "axios";
import Rights from "@/Rights.js";
import store from "@/store/store";
export const namespaced = true;

export const state = {	
    customers: [],
    customer: null
};

export const mutations = {
    clearCustomers(state) {
        state.customers = [];
    },  
    setCustomers(state, data) {          
        data.forEach(element => {
            element.name = element.firstname + ' ' + element.lastname 
            state.customers.push(element)
        });               
    },
    clearCustomer(state) {
        state.customer = null;
    },  
    setCustomer(state, data) {          
        state.customer = data;
    },
}

export const actions = {
    getCustomers({ commit }) {                    
        return client.core.get('/accounts')
        .then((r) => {                               
            commit('clearCustomers')
            commit('setCustomers', r.data)
            return r.data          
        })
    },
    updateEmail({commit}, user) {
        const config = { headers: {'Content-Type': 'application/json'} };
        return client.core.patch('/admin/accounts/email/' +  user.id, user.email, config).then((r) => {
            if(r.status === 200) {
                return client.webcore.post('/changeemail', user).then((r) => {
                    return r.data
                })
            }                        
        })
    },
    getMandator({ commit }, data) {
        return client.core.get('/accounts/payment/' + data)
        .then((r) => {
           return r.data.mandator.id
        })
    },
    getCustomerDetails({commit}, mollieId) {
        return client.webcore.get('/customers/' + mollieId)
        .then((r) => {
            r.data.customerRights = new Rights(r.data.deser.granted)
            r.data.grantable = new Rights(r.data.deser.grantable)
            commit('clearCustomer')
            commit('setCustomer', r.data)
        })
    },
    updatePermissions({commit}, customer) {
        return client.core.patch('/accounts/permissions/' + customer.deser.id, customer.deser.granted, {headers: {"Content-Type": "application/json"}})
        .then((r) => {
            store.dispatch(
                "messageHandling/addMessage",
                'Benutzerrechte erfolgreich bearbeitet'
            );
            //commit('updatemandator', r.data)
            return r.data
        })
    }
}

