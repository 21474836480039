import Vue from 'vue'
import { client } from "@/store/client.js";
import axios from "axios";
export const namespaced = true;
import Vuetify from '../../vuetify'
Vue.prototype.$vuetify = Vuetify;
export const state = {	
	isLoading: false,
	left_sidebar: Vue.prototype.$vuetify.framework.breakpoint.mdAndDown ? false : true,
};

export const mutations = {
    loading(state, isLoading) {							
        state.isLoading = isLoading;			
    },
    CHANGE_LEFT_SIDEBAR(state) {
        state.left_sidebar = !state.left_sidebar
    },
    SET_LEFT_SIDEBAR(state, value){
        state.left_sidebar = value;
    },
}
export const actions = {
    toggleSidebarState({commit}){
        commit("CHANGE_LEFT_SIDEBAR");
    },
    
    setSidebarState({commit}, value){
        commit("SET_LEFT_SIDEBAR", value);
    },
}

export const getters = {
	
}