import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store"
import preventUnsaved from "@/store/preventunsaved"

import app from "./main.js"
Vue.use(Router);

const App = () => import("@/App.vue");
const Main = () => import("@/Layout/Main.vue");
const Login = () => import("@/components/Login.vue");
const Customers = () => import("@/components/Customers/Customers.vue");
/* 
const admin = function(to,from,next) {	
	const loggedIn = store.getters["authModule/loggedIn"];
	const isUser = localStorage.getItem('current_user');	
	if(isUser) {
		next({name: 'dashboard'})
	}	
} */
const router =  new Router({
    mode: "history",
    routes: [
        {
            path: "/",
			name: "home",
			meta: {
				auth: true, name: 'home',visible: false
			},
			component: App,
			beforeEnter: function(to,from,next) {	
				const loggedIn = store.getters["authModule/loggedIn"];
				const isUser = localStorage.getItem('current_user');	
				if(isUser) {
					next({name: 'dashboard'})
				} 				
			}
        },
		{
			path: "/login",
			name: "Login",
			meta: {
				auth: false, name: 'Login',visible: false
			},
			component: Login
		},
		{
			path: "/app",
			meta: {
				auth: true, name: 'app',visible: false,
				isRoot: true
			},
			component: Main,
			children: [
				{
					path: "dashboard",
					name: "dashboard",
					meta: {
						auth: true, name: 'dashboard', isNavLink: true,
						label: 'Dashboard', icon: 'mdi-home'
					},
					component: () => import("@/components/Dashboard.vue"),
				},
				{
					path: "customers",
					meta: {
						auth: true, name: 'customers', isNavLink: true,
						label: 'Benutzer', icon: 'mdi-account'
					},
					component: () => import("@/components/Customers/Customers.vue"),
					children: [
						{
							path: "",
							name: "customers",
							meta: { name: 'customers', auth: true, label: 'Kunden'},
							component: () => import("@/components/Customers/CustomersTable.vue")
						},
						{
							path: ":customerId",
							name: "customersDetails",
							meta: { name: 'customers', auth: true },
							component: () => import("@/components/Customers/CustomersDetails.vue")
						},
					]
				},
				{
					path: "mandators",
					meta: {
						auth: true, name: 'mandators', isNavLink: true,
						label: 'Mandanten', icon: 'mdi-account-supervisor-circle-outline'
					},
					component: () => import("@/components/Mandators/Mandators.vue"),
					children: [
						{
							path: "",
							name: "mandators",
							meta: { name: 'mandators', auth: true, label: 'Mandanten'},
							component: () => import("@/components/Mandators/MandatorsTable.vue")
						},
						{
							path: ":mandatorId",
							name: "mandatorsDetails",
							meta: { name: 'mandators', auth: true },
							component: () => import("@/components/Mandators/MandatorsDetails.vue")
						},
					]
				},
				{
					path: "invoices",
					name: 'invoices',
					meta: {
						auth: true, name: 'invoices', isNavLink: true,
						label: 'Rechnungen', icon: 'mdi-receipt'
					},
					component: () => import("@/components/Invoices/Invoices.vue")
				},
				{
					path: "cars",
					meta: {
						auth: true, name: 'cars', isNavLink: true,
						label: 'Fahrzeuge', icon: 'mdi-car'
					},
					component: () => import("@/components/Cars/Cars.vue"),
					children: [
						{
							path: "",
							name: "cars",
							meta: { name: 'cars', auth: true, label: 'Fahrzeuge'},
							component: () => import("@/components/Cars/CarsTable.vue")
						},
						{
							path: ":carId",
							name: "carsDetails",
							meta: { name: 'cars', auth: true },
							component: () => import("@/components/Cars/CarsDetails.vue")
						},
					]
				},	
				{
					path: "disposers",
					meta: {
						auth: true, name: 'disposers', isNavLink: true,
						label: 'Entsorger', icon: 'mdi-recycle'
					},
					component: () => import("@/components/Disposers/Disposers.vue"),
					children: [
						{
							path: "",
							name: "disposers",
							meta: { name: 'disposers', auth: true, label: 'Entsorger'},
							component: () => import("@/components/Disposers/DisposersTable.vue")
						},
						{
							path: ":disposerId",
							name: "disposersDetails",
							meta: { name: 'disposers', auth: true },
							component: () => import("@/components/Disposers/DisposersDetails.vue")
						},
					]
				},	
				{
					path: "materials",
					name: 'materials',
					meta: {
						auth: true, name: 'materials', isNavLink: true,
						label: 'Materialien', icon: 'mdi-file'
					},
					component: () => import("@/components/Materials/Materials.vue")
				},	
				{
					path: "lastreceptions",
					name: 'lastreceptions',
					meta: {
						auth: true, name: 'lastreceptions', isNavLink: true,
						label: 'Letzte Fahrzeugannahmen', icon: 'mdi-car'
					},
					component: () => import("@/components/LastReceptions/LastReceptions.vue")
				},	
				/* {
					path: "contracts",
					name: 'contracts',
					meta: {
						auth: true, name: 'contracts', isNavLink: true,
						label: 'Verträge', icon: 'mdi-file'
					},
					component: () => import("@/components/Contracts/Contracts.vue")
				},		 */
				{
					path: "certifiers",
					meta: {
						auth: true, name: 'certifiers', isNavLink: true,
						label: 'Zertifizierer', icon: 'mdi-certificate'
					},
					component: () => import("@/components/Certifiers/Certifiers.vue"),
					children: [
						{
							path: "",
							name: "certifiers",
							meta: { name: 'certifiers', auth: true, label: 'Zertifizierer'},
							component: () => import("@/components/Certifiers/CertifiersTable.vue")
						},
						{
							path: "new",
							name: "certifiersNew",
							meta: { name: 'certifiers', auth: true },
							component: () => import("@/components/Certifiers/CertifiersDetails.vue")
						},
						{
							path: ":certifierId",
							name: "certifiersDetails",
							meta: { name: 'certifiers', auth: true },
							component: () => import("@/components/Certifiers/CertifiersDetails.vue")
						},
					]
				},				
				{
					path: "import",
					name: 'import',
					meta: {
						auth: true, name: 'import', isNavLink: true,
						label: 'Import', icon: 'mdi-application-import'
					},
					component: () => import("@/components/Import/Import.vue")
				}
			]
		}
    ]
})

router.beforeEach(preventUnsaved)

router.beforeEach(async (to,from,next) => {
		if (to.matched.some(record => record.meta.auth)) {
			const loggedIn = localStorage.getItem('current_user')
			if(!loggedIn) {
				next({name: 'Login'})
				return
			} else {
				next()
				return
			}
		} else {
			next() // does not require auth, make sure to always call next()!
		}	
})

export default router