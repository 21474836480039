export const namespaced = true;
import {uniqueId, findIndex} from 'underscore'

export const state = {
  APIerrors: [],
  messages: []
};

export const mutations = {
    ADD_ERROR(state, APIerror) {
      console.log(APIerror)
        var errorIndex = state.APIerrors.length;
        var errorobj = {}
        if(APIerror.data.status && !!state.APIerrors.find(error => error.data.status === APIerror.data.status)) {      
          errorobj = { id: uniqueId('item_'), errorIndex: errorIndex, APIerror };
          state.APIerrors.push(errorobj);      
        } else {
          errorobj = { id: uniqueId('item_'), errorIndex: errorIndex, APIerror };
          //   console.log("Fehlerobjekt: ", errorobj);
          state.APIerrors.push(errorobj);
        }
    },
    ADD_MESSAGE(state, message) {
        var messageIndex = state.messages.length;
        var messageobj = { id: uniqueId('item_'), messageIndex: messageIndex, message };
        state.messages.push(messageobj);
    },
    CLEAR_ERROR(state) {
        state.APIerrors = [];
    },
    CLEAR_MESSAGE(state) {
        state.messages = [];
    }
}

export const actions = {
    addError({ commit }, APIerror) {
      // console.log("Action: Fehlerobjekt: ", APIerror)
      commit("ADD_ERROR", APIerror);
    },
    addMessage({ commit }, message) {
      commit("ADD_MESSAGE", message);
    },
    clearError({ commit }, index) {
      commit("CLEAR_ERROR", index);
    },
    clearMessage({ commit }, index) {
      commit("CLEAR_MESSAGE", index);
    }
  };
  