import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import de from 'vuetify/es5/locale/de'
Vue.use(Vuetify)

const vuetify_opts = {	
	lang: {
		locales: {
			de
		},
		current : "de",
		
	},
	options: {
		customProperties : true	
	},
	theme: { dark: false },
	themes: {
		dark : {
			primary: "#337AB7",
			secondary: "#424242",
			accent: "#4d505b",
			success: "#00A65A",
			info: "#2196F3",
			warning: "#F39C12",
			error: "#eb4d4b",
			"colorsets-main-bg-primary": "#efeff9",// blueish white
			"colorsets-main-bg-secondary": "#ddd",// blueish white
			"colorsets-main-bg-quantary": "#ddd",
			"colorsets-main-bg-quinary": "#e4e5e6",
			"colorsets-main-bg": "#fff",
			"colorsets-main-font-color": "#333",
			"colorsets-main-button-color": "#eb4d4b", // ecar-red
			"colorsets-main-header" : "#337ab7",
			"colorsets-main-table-bg" : "#bbdefb",
			"whitey": "#eee"
		}
	},
};

export default new Vuetify(vuetify_opts)