import Vue from 'vue'
import Vuex from 'vuex'
import * as authModule from "@/store/modules/Auth.js";
import * as appModule from "@/store/modules/App.js";
import * as customersModule from "@/store/modules/Customers.js";
import * as adminModule from "@/store/modules/Admin.js";
import * as disposersModule from "@/store/modules/Disposers.js";
import * as carsModule from "@/store/modules/Cars.js";
import * as dismantlersModule from "@/store/modules/Dismantlers.js";
import * as certifiersModule from "@/store/modules/Certifiers.js";
import * as mandatorsModule from "@/store/modules/Mandators.js";
import * as messageHandling from "@/store/messageHandling.js";
import * as contractsModule from "@/store/modules/Contracts.js";
import * as materialsModule from "@/store/modules/Materials.js";
import * as lastreceptionsModule from "@/store/modules/LastReceptions.js";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        authModule,
        dismantlersModule,
        carsModule,
        appModule,
        customersModule,
        adminModule,
        disposersModule,
        certifiersModule,
        mandatorsModule,
        contractsModule,
        materialsModule,
        lastreceptionsModule,
        messageHandling
    },
    state: {
        preventUnsaved: false,
        unsavedmodal: false,
        unsaveddiscard: false,
        checkunsaved: false,
        unsavedcancel: false
    },
    mutations: {
        setPreventUnsaved: (state, value) => (state.preventUnsaved = value),
        setUnsavedModal: (state, value) => (state.unsavedmodal = value),
        setCheckUnsaved: (state, value) => (state.checkunsaved = value),
    }
})

export default store