import store from "@/store/store";
import {checkBox} from "@/store/unsavedmodal.js";

export default async (to, from, next) => {

    console.log('beforeEach', store.state.preventUnsaved);
    if(store.state.preventUnsaved) {
        console.log('preventUnload');
        const answer = await checkBox();
        console.log('checkBox: ', answer)
        if (answer) {
            store.commit('setPreventUnsaved',  false);
            store.commit('setUnsavedModal', false);    
            store.commit('setCheckUnsaved', false);    
            next()
        } else {
            console.log('preventing next');
            store.commit('setPreventUnsaved',  false);
            store.commit('setUnsavedModal', false);    
            store.commit('setCheckUnsaved',  false);
            return next(false)
        }
    } else next();
};
