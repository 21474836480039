import client from "@/store/client.js";
import Rights from "@/Rights.js";
import store from "@/store/store";

export const namespaced = true;
const date = new Date(Date.now() + ( 3600 * 1000 * 24)) 

const defaultdates = {
  from: new Date(new Date().getFullYear(), 0, 1).toLocaleDateString('en-CA'),
  to: date.toLocaleDateString('en-CA'),
};
export const state = {	
    cars: [],
};

export const mutations = {
    clearcars(state) {
        state.cars = [];
    },  
    setcars(state, data) {          
        data.forEach(element => {            
            state.cars.push(element)
        });               
    },    
    updatecars(state, data) {
        state.cars.splice(data.index,1)     
    }, 
}

export const actions = {
    getCars({commit}, data) {
        if(data.dates) {
            return client.core.get('/cars/of/' + data.dismantlerId + '/' + data.dates.from + '/' + data.dates.to).then((r) => {
                commit("clearcars")
                commit("setcars", r.data)            
                return r.data
            });
          } else {
            return client.core.get('/cars/of/' + data.dismantlerId + '/' + defaultdates.from + '/' + defaultdates.to).then((r) => {
                commit("clearcars")
                commit("setcars", r.data)            
                return r.data
            }); 
          }         
        
    },
    deleteCar({commit}, data) {
        return client.core.delete('/admin/cars/' + data.id)
        .then((r) => {
            if(r.status === 200) {         
                store.dispatch(
                    "messageHandling/addMessage",
                    'Fahrzeug erfolgreich gelöscht'
                );                                 
                commit("updatecars", data)         
            }  
        })
    }
}