import axios from "axios";
import store from '@/store/store';
export const namespaced = true;

const Config = {
    webcoreUrl: process.env.VUE_APP_WEBCORE_API,
    coreUrl: process.env.VUE_APP_CORE_API
};

const client = {
    webcore: axios.create({
        baseURL: Config.webcoreUrl,
        
    }),
    core: axios.create({
        baseURL: Config.coreUrl
    })
}

client.webcore.interceptors.request.use(request => {
    store.commit("appModule/loading", true)
    return request
})

client.webcore.interceptors.response.use(response => {
    store.commit("appModule/loading", false)
    return response
}, error => {
    store.commit("appModule/loading", false)
})

client.core.interceptors.request.use(request => {
    store.commit("appModule/loading", true)
    return request
})

client.core.interceptors.response.use(response => {
    store.commit("appModule/loading", false)
    return response
}, error => {
    store.commit("appModule/loading", false)
    console.log(error.response)
    store.dispatch(
        "messageHandling/addError",
        error.response
    ); 
})

export default client