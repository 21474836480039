import MBOX from  '@/Layout/UnsavedM.vue'
import UNSAVED from  '@/Layout/UnsavedModal.vue'
import _Vue from 'vue';
import store from "@/store/store";

export function checkBox() {
    store.commit('setUnsavedModal', true);    
    return new Promise((resolve,reject) => {
        if (store.state.preventUnsaved) {
            
            store.$vm.$watch('$store.state.checkunsaved', function(newVal,oldVal){
                console.log(newVal)
                resolve(newVal)
            },{deep: true})
            //resolve(true);
        }
    })
}


export function mbox(mText, mTitle, mBtnCap1, mBtnCap2, mBtnCap3) {
    return new Promise( (resolve, reject) => {
        if (!mTitle){
            mTitle = 'My Title';
        }
        if (!mBtnCap1){
            mBtnCap1 = 'OK';
        }
        
        // I'm combining a bunch of stuff to make this work.
        // First, create the vue component
        var mboxInstance = _Vue.extend(MBOX); // mbox component, imported at top of Sublib
        var oComponent = new mboxInstance({ 
            propsData: { 
                msg: mText, 
                title: mTitle, 
                btnCap1: mBtnCap1, 
                btnCap2: mBtnCap2, 
                btnCap3: mBtnCap3,
                retval: false
                }
        }).$mount();
        //store.commit('setUnsavedModal',  true);
        // now add it to the DOM
        var oEl = document.getElementById('main').appendChild(oComponent.$el);

        // NOTE: couldn't get it to work without adding a 'button' to activate it
        // progrmatically click it and make the button invisible
        // document.getElementById('mbox_btn_launch').click();
        var oLuanchBtn = document.getElementById('mbox_btn_launch');
        oLuanchBtn.style.visibility = 'hidden';
        //oLuanchBtn.click();

        // Add a listener so we can get the value and return it
        oComponent.$watch('retval', function(newVal, oldVal){
            // this is triggered when they chose an option
            // Delete the component / element now that I'm done
            oEl.remove();
            resolve(Number(newVal));
        })
    }); // promise
} // mbox