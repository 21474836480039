export default class Material{
	constructor( material ){
        /* properties list */
        this.id = null;
		this.nameDe = null;
		this.nameEn = null;
		this.currency = null;
		this.price = null;
		this.measure = null;
		this.percentage = null;
		

		/* if given data from API, assign it into field values */
		if( !!material ){
			//console.log("material construction with data");
			//console.log(material);

			if(typeof(material.id)          !== "undefined") this.id      = material.id;
			if(typeof(material.nameDe)        !== "undefined") this.nameDe      = material.nameDe;
            if(typeof(material.nameEn)     !== "undefined") this.nameEn   = material.nameEn;
            if(typeof(material.currency)         !== "undefined") this.currency       = material.currency;
			if(typeof(material.price)        !== "undefined") this.price      = material.price.toLocaleString('de',  { style: 'currency', currency: material.currency });
			if(typeof(material.percentage)      !== "undefined") this.percentage    = material.percentage.toLocaleString('de');
			if(typeof(material.measure)      !== "undefined") this.measure    = material.measure;			
            
		} else {
			// console.log("certifier construction without data");

			/**/
		}
	}
}
