import client from "@/store/client.js";
import axios from "axios";
export const namespaced = true;

export const state = {	
	admin: null
};

const setUserData = function(userData) {    
    client.core.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`
    client.webcore.defaults.headers.common["Authorization"] = `Bearer ${userData.admintoken}`
}

export const mutations = {
    set_User_Data (state, userData) {         
        state.admin = userData
        localStorage.setItem('current_user', JSON.stringify(userData))        
        setUserData(userData)
    },
}
export const actions = {
    login ({ commit }, credentials) {                    
        return client.webcore.post('/login', credentials)
        .then(({ data }) => {            
            if(data.core.admin) {
                commit('set_User_Data', data)
                return data
            }    
        })
    },
    async checkAuth({commit}, userData) {
        return await client.webcore.post('/confirmauth', userData)
        .then(({ data }) => {
            if(data.core.admin) {
                state.admin = userData
                commit('set_User_Data', state.admin)
                return data
            }    
        })
    },
    logout() {
        localStorage.removeItem('current_user');
    }
}

export const getters = {
	loggedIn:state => !!state.admin,
	loggedInUser: state => state.admin, 
}