import Vue from 'vue'
import vuetify from './vuetify'
import App from './App.vue'
import router from "./router";
import store from './store/store';
import client from "@/store/client.js";
import axios from 'axios'
import './assets/app.scss';

Vue.config.productionTip = false

const userInfo = localStorage.getItem('current_user')
const userData = JSON.parse(userInfo)
if (userData) {            
  axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`
  client.webcore.defaults.headers.common["Authorization"] = `Bearer ${userData.admintoken}`
  client.core.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`
  store.dispatch('authModule/checkAuth', userData)
}

const vm = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

store.$vm = vm

export default vm;

