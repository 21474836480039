import client from "@/store/client.js";
import store from "@/store/store";

export const namespaced = true;

export const mutations = {
    clearsettlements(state) {
        state.settlements = []        
    },  
    setsettlements(state, data) {                  
        state.settlements = data               
    },
    clearinvoices(state) {
        state.invoices = []        
    },  
    setinvoices(state, data) {
        data.forEach(order => {
            if(order.mollie_payment_status === 'paid') { order.mollie_payment_status = 'Bezahlt' }
            if(order.mollie_payment_status === 'open') { order.mollie_payment_status = 'Offen' }
            if(order.mollie_payment_status === 'failed') { order.mollie_payment_status = 'Fehlgeschlagen' }
            if(order.mollie_payment_status === 'pending') { order.mollie_payment_status = 'Warte auf Zahlung' }
            if(order.mollie_payment_status === 'expired') { order.mollie_payment_status = 'Abgelaufen' }
            if(order.mollie_payment_status === 'canceled') { order.mollie_payment_status = 'Abgebrochen' }
        });             
        state.invoices = data               
    },
    cleardashboard(state) {
        state.dashboard = []        
    },  
    setdashboard(state, data) {                  
        state.dashboard = data               
    },
}

export const state = {
    settlements: [],
    dashboard: [],
    invoices: []
}

export const actions = {
    getInitial({commit}) {
        return client.webcore.get('/initial')
        .then((r) => {
            commit("cleardashboard")
            commit("setdashboard", r.data)
            return r.data
        })
    },
    getMethods({commit}) {
        return client.webcore.get('/pmethods')
        .then((r) => {
            return r.data
        })
    },
    getSettlements({commit}) {
        return client.webcore.get('/settlements')
        .then((r) => {
            commit("setsettlements", r.data)
            return r.data
        })
    },
    getInvoices({commit}) {
        return client.webcore.get('/invoices')
        .then((r) => {
            commit("clearinvoices")
            commit("setinvoices", r.data.orders)
            return r.data.orders
        })
    },
    downloadInvoice({commit}, order) {
        return client.webcore.get('/downloadinvoice/' + order.mollie_payment_id, {responseType: 'blob'})
        .then(( r ) => {
        var fileURL = window.URL.createObjectURL(new Blob([r.data], {type: 'application/pdf'}));
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(fileURL)
            return
          }
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download','Rechnung-' + order.number + '.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(fileURL)
        }, 100)
        })
    },
    exportSettlement({commit}, id) {
        return client.webcore.get('/settlements/export/' + id)
        .then((r) => {
            var fileURL = window.URL.createObjectURL(new Blob([r.data], {type: 'data:text/csv;charset=utf-8,%EF%BB%BF'}));
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(fileURL)
            return
          }
        var fileLink = document.createElement('a');
        /* fileLink.href = fileURL; */
        fileLink.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF"+ encodeURI(r.data));
        fileLink.setAttribute('download','settlements.csv');
        document.body.appendChild(fileLink);
        fileLink.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(fileURL)
        }, 100)
            return r.data
        })
    },
    unlockBtb({commit}, mandator) {
        return client.webcore.post('/unlockbtb', mandator)
        .then((r) => {
            store.dispatch(
                "messageHandling/addMessage",
                'Betriebstagebuch erfolgreich aktiviert'
            );
            return r.data
        })
    },
    lockBtb({commit}, mandator) {
        return client.webcore.post('/lockbtb', mandator)
        .then((r) => {
            store.dispatch(
                "messageHandling/addMessage",
                'Betriebstagebuch erfolgreich deaktiviert'
            );
            return r.data
        })
    },
    deleteUser({commit}, userId) {
        return client.core.delete('/accounts/' + userId)
        .then((r) => {
            store.dispatch("customersModule/getCustomers")
            store.dispatch(
                "messageHandling/addMessage",
                'Account erfolgreich gelöscht'
            );
            return r.data
        })  
    },
    deactivateUser({commit}, userId) {
        return client.core.patch('/accounts/deactivate/' + userId)
        .then((r) => {
            store.dispatch(
                "messageHandling/addMessage",
                'Account erfolgreich deaktiviert'
            );
            return r.data
        })  
    },
    activateUser({commit}, userId) {
        return client.core.patch('/accounts/activate/' + userId)
        .then((r) => {
            store.dispatch(
                "messageHandling/addMessage",
                'Account erfolgreich aktiviert'
            );
            return r.data
        })
    },
    updateMandantorName({commit}, mandator) {
        return client.core.patch('/accounts/activate/' + mandator)
        .then((r) => {
            return r.data
        })
    },
    importEcarData({commit}, data) {
        return client.core.get('/admin/ecar/import/' + data.selectedimport + '/' + data.mandatorselected + '/' + data.mandatorselected + '/' + data.datefrom)
        .then((r) => {
            return r.data
        })
    },
    importUpmData({commit}, data ) {
        let formData = new FormData();
        formData.append("file", data.file);
        return client.core.post('/admin/import/upm/' + data.dismantler + '?m=' + data.mid, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }}
        ).then((r) => {
            return r.data
        })
    }
}