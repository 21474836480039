export default class Certifier{
	constructor( certifier ){
        /* properties list */
        this.id = null;
		this.name = null;
		this.country = null;
		this.zip = null;
		this.city = null;
		this.street = null;
		this.hsnr = null;
		this.company = null;
		this.tel = null;
		this.fax = null;
		this.mobile = null;
		this.email = null;
		this.title = null;
		this.authority = null;
		this.expertise = null;		
		this.web = null;

		/* if given data from API, assign it into field values */
		if( !!certifier ){
			//console.log("certifier construction with data");
			//console.log(certifier);

			if(typeof(certifier.id)          !== "undefined") this.id      = certifier.id;
			if(typeof(certifier.name)        !== "undefined") this.name      = certifier.name;
            if(typeof(certifier.country)     !== "undefined") this.country   = certifier.country;
            if(typeof(certifier.zip)         !== "undefined") this.zip       = certifier.zip;
			if(typeof(certifier.city)        !== "undefined") this.city      = certifier.city;
			if(typeof(certifier.street)      !== "undefined") this.street    = certifier.street;
			if(typeof(certifier.hsnr)        !== "undefined") this.hsnr      = certifier.hsnr;
			if(typeof(certifier.company)     !== "undefined") this.company      = certifier.company;
			if(typeof(certifier.tel)         !== "undefined") this.tel      = certifier.tel;
			if(typeof(certifier.fax)         !== "undefined") this.fax      = certifier.fax;
			if(typeof(certifier.mobile)      !== "undefined") this.mobile      = certifier.mobile;
			if(typeof(certifier.email)       !== "undefined") this.email      = certifier.email;
			if(typeof(certifier.title)       !== "undefined") this.title      = certifier.title;
			if(typeof(certifier.authority)   !== "undefined") this.authority      = certifier.authority;
			if(typeof(certifier.expertise)   !== "undefined") this.expertise      = certifier.expertise;
			if(typeof(certifier.web)         !== "undefined") this.web      = certifier.web;
            
		} else {
			// console.log("certifier construction without data");

			/**/
		}
	}
}
