<template>
  <v-row justify="center">
    <v-dialog v-model="preventUnsaved" persistent max-width="500">
      <v-card>
        <v-card-title :class="['headline  white--text ', type]" primary-title >{{headline}}</v-card-title>
        <v-card-text class="pt-3 subtitle-1">{{text}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        <v-card-actions>
          <v-spacer></v-spacer>  
          <v-btn :color="type === 'error' ? 'success' : 'info darken-1'"  @click="onCancel">{{cancelText}}</v-btn>
          <v-btn :color="type === 'error' ? 'success' : 'error darken-1'"  @click="onDiscard">{{discardText}}</v-btn>
          <v-btn :color="type ? type : 'success darken-1'"  @click.prevent="onConfirm">{{confirmText}}</v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
    props : {
        dialog : {type : Boolean, default: false},
        data: {default: null},
        headline : {type: String, default: "Bestätigen"},
        function : {default: null},
        type : {type: String, default: ""},
        text : {type: String, default: "Bitte bestätigen Sie die Aktion."},
        cancelText : { type : String, default: 'Abbrechen'},
        confirmText : { type : String, default: 'Speichern'},
        discardText : { type : String, default: 'Verwerfen'},
        
    },
    data: () => ({
        returnvalue: true    
    }),
    computed: {
      ...mapState({
        preventUnsaved: state => state.unsavedmodal
      })
    },
    methods : {
        onCancel(){
            this.$emit("onUnsavedCancel");
            this.$store.commit('setCheckUnsaved', false);
            //this.$refs.foo.$on("onCancel", this.doSomething);
        },
        onDiscard() {
          this.$store.commit('setCheckUnsaved', true);
        },
        onConfirm(){
            this.$emit("confirm", this.function);
        }
    },
}
</script>