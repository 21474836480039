import client from "@/store/client.js";
import store from "@/store/store";
import Material from '@/store/classes/Material.js'
export const namespaced = true;

export const state = {
    materials: []
}

export const mutations = {
    clearMaterials(state) {
        state.materials = []
    },
    setMaterials(state, data) {
        data.forEach(element => {
            state.materials.push(new Material(element))
        }); 
    },
    setMaterial(state, data) {
        let mat = state.materials.find((r) => r.id === data.id)
        if(mat) {            
            Object.assign( mat, data);
        } else {
            state.materials.push(new Material(data))
        }        
    },
    updateMaterial(state, data) {
        
    }
}

export const actions = {
    createUpdateMaterial({commit}, data) {
        return client.core.put('/admin/purchaser/materials',  data).then(material => {            
            if(material.status === 200) {                                                    
                commit('setMaterial', material.data)                
            }
        })
    },
    getMaterials({commit}, data) {
        return client.core.get('/purchaser/materials').then(materials => {
            if(materials.status === 200) {
                commit("clearMaterials")
                commit("setMaterials", materials.data)
            }
        })
    }
}